/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import * as posedetection from '@tensorflow-models/pose-detection';

export const DEFAULT_LINE_WIDTH = 4;
export const DEFAULT_RADIUS = 8;

export const VIDEO_SIZE = {
  '640 X 480': {width: 640, height: 480},
  '480 X 640': {width: 480, height: 640},
  '640 X 360': {width: 640, height: 360},
  '360 X 270': {width: 360, height: 270}
};
export const STATE = {
  camera: {targetFPS: 60, sizeOption: '480 X 640'},
  backend: '',
  flags: {},
  modelConfig: {}
};
export const BLAZEPOSE_CONFIG = {
  maxPoses: 1,
  type: 'full',
  scoreThreshold: 0.65,
  render3D: false
};
export const POSENET_CONFIG = {
  maxPoses: 1,
  scoreThreshold: 0.5
};
export const MOVENET_CONFIG = {
  maxPoses: 1,
  type: 'lightning',
  scoreThreshold: 0.3,
  customModel: '',
  enableTracking: false
};
/**
 * This map descripes tunable flags and theior corresponding types.
 *
 * The flags (keys) in the map satisfy the following two conditions:
 * - Is tunable. For example, `IS_BROWSER` and `IS_CHROME` is not tunable,
 * because they are fixed when running the scripts.
 * - Does not depend on other flags when registering in `ENV.registerFlag()`.
 * This rule aims to make the list streamlined, and, since there are
 * dependencies between flags, only modifying an independent flag without
 * modifying its dependents may cause inconsistency.
 * (`WEBGL_RENDER_FLOAT32_CAPABLE` is an exception, because only exposing
 * `WEBGL_FORCE_F16_TEXTURES` may confuse users.)
 */
export const TUNABLE_FLAG_VALUE_RANGE_MAP = {
  WEBGL_VERSION: [1, 2],
  WASM_HAS_SIMD_SUPPORT: [true, false],
  WASM_HAS_MULTITHREAD_SUPPORT: [true, false],
  WEBGL_CPU_FORWARD: [true, false],
  WEBGL_PACK: [true, false],
  WEBGL_FORCE_F16_TEXTURES: [true, false],
  WEBGL_RENDER_FLOAT32_CAPABLE: [true, false],
  WEBGL_FLUSH_THRESHOLD: [-1, 0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
  CHECK_COMPUTATION_FOR_ERRORS: [true, false],
};

export const BACKEND_FLAGS_MAP = {
  ['tfjs-wasm']: ['WASM_HAS_SIMD_SUPPORT', 'WASM_HAS_MULTITHREAD_SUPPORT'],
  ['tfjs-webgl']: [
    'WEBGL_VERSION', 'WEBGL_CPU_FORWARD', 'WEBGL_PACK',
    'WEBGL_FORCE_F16_TEXTURES', 'WEBGL_RENDER_FLOAT32_CAPABLE',
    'WEBGL_FLUSH_THRESHOLD'
  ],
  ['mediapipe-gpu']: []
};

export const MODEL_BACKEND_MAP = {
  [posedetection.SupportedModels.PoseNet]: ['tfjs-webgl'],
  [posedetection.SupportedModels.MoveNet]: ['tfjs-webgl', 'tfjs-wasm'],
  [posedetection.SupportedModels.BlazePose]: ['mediapipe-gpu', 'tfjs-webgl']
}

export const TUNABLE_FLAG_NAME_MAP = {
  PROD: 'production mode',
  WEBGL_VERSION: 'webgl version',
  WASM_HAS_SIMD_SUPPORT: 'wasm SIMD',
  WASM_HAS_MULTITHREAD_SUPPORT: 'wasm multithread',
  WEBGL_CPU_FORWARD: 'cpu forward',
  WEBGL_PACK: 'webgl pack',
  WEBGL_FORCE_F16_TEXTURES: 'enforce float16',
  WEBGL_RENDER_FLOAT32_CAPABLE: 'enable float32',
  WEBGL_FLUSH_THRESHOLD: 'GL flush wait time(ms)'
};

export const KEYPOINTS = {
    NOSE: 0,
    LEFT_EAR: 7,
    RIGHT_EAR: 8,
    LEFT_MOUTH: 9,
    RIGHT_MOUTH: 10,
    LEFT_SHOULDER: 11,
    RIGHT_SHOULDER: 12,
    LEFT_ELBOW: 13,
    RIGHT_ELBOW: 14,
    LEFT_WRIST: 15,
    RIGHT_WRIST: 16,
    LEFT_HIP: 23,
    RIGHT_HIP: 24,
    LEFT_KNEE: 25,
    RIGHT_KNEE: 26,
    LEFT_ANKLE: 27,
    RIGHT_ANKLE: 28,
};

export const ASSESSMENT_POSITION = {
  NOT_STARTED: "not_started",
  SIT_TO_STAND: "sit_to_stand",
  SHOULDER_ABDUCTION: "shoulder_abduction",
  SHOULDER_FLEXION: "shoulder_flexion",
  SHOULDER_EXTENSION: "shoulder_extension",
  ELBOW_FLEXION: "elbow_flexion",
  CERVICAL_FLEXION: "cervical_flexion",
  CERVICAL_EXTENSION: "cervical_extension",
  CERVICAL_LATERAL_FLEXION: "cervical_lateral_flexion",
  LUMBAR_FLEXION: "lumbar_flexion",
  LUMBAR_EXTENSION: "lumbar_extension",
  LUMBAR_LATERAL_FLEXION: "lumbar_lateral_flexion",
  HIP_FLEXION: "hip_flexion",
  HIP_EXTENSION: "hip_extension",
  SQUAT_KNEE_FLEXION: "squat_knee_flexion",
  CERVICAL_ROTATION: "cervical_rotation",
  HIP_INTERNAL_ROTATION: "hip_internal_rotation",
  HIP_EXTERNAL_ROTATION: "hip_external_rotation",
  SQUAT_HIP_FLEXION: "squat_hip_flexion",
  COMPLETE: "assessment_completed",
  END: "assessment_finish"
};

export const ASSESSMENT_STATUS = {
  NOT_STARTED: "Get in frame",
  SET_UP: "Get in starting position",
  SIT_TO_STAND: "Sit to Stand",
  SHOULDER_ABDUCTION: "Shoulder abduction",
  SHOULDER_FLEXION: "Shoulder flexion",
  SHOULDER_EXTENSION: "Shoulder extension",
  ELBOW_FLEXION: "Elbow flexion",
  CERVICAL_FLEXION: "Cervical flexion",
  CERVICAL_EXTENSION: "Cervical extension",
  CERVICAL_LATERAL_FLEXION: "Cervical lateral flexion",
  LUMBAR_FLEXION: "Lumbar flexion",
  LUMBAR_EXTENSION: "Lumbar extension",
  LUMBAR_LATERAL_FLEXION: "Lumbar lateral flexion",
  HIP_FLEXION: "Hip flexion",
  HIP_EXTENSION: "Hip extension",
  SQUAT_KNEE_FLEXION: "Squat knee flexion",
  CERVICAL_ROTATION: "Cervical rotation",
  HIP_INTERNAL_ROTATION: "Hip internal rotation",
  HIP_EXTERNAL_ROTATION: "Hip external rotation",
  SQUAT_HIP_FLEXION: "Squat hip flexion",
  END: "Assessment completed"
};

export const ASSESSMENT_NOTIFICATIONS = {
  BODY_NOT_IN_FRAME: "Get body inside frame.",
  BODY_TOO_CLOSE: "Step further away to the camera.",
  GOOD_JOB: "Good job.",
  SIT_TO_STAND: "Do as many sit ups as you can within the time.",
  SHOULDER_ABDUCTION: "Raise your arms to the side and reach over head and hold.",
  SHOULDER_FLEXION: "Raise your arms forward and reach over head and hold.",
  SHOULDER_EXTENSION: "Move your arms behind you and hold.",
  ELBOW_FLEXION: "Bend your elbow and hold.",
  CERVICAL_FLEXION: "Move your head to look down to the floor.",
  CERVICAL_EXTENSION: "Move your head to look up to the ceiling.",
  CERVICAL_LATERAL_FLEXION: "Tilt your head to the right, and then, tilt your head to the left.",
  LUMBAR_FLEXION: "Keeping your knees straight, bend over to touch your toes.",
  LUMBAR_EXTENSION: "Keeping your legs straight, bend backwards.",
  LUMBAR_LATERAL_FLEXION: "Bend to the left, and then, bend to the right.",
  HIP_FLEXION: "Raise your knee up towards your chest.",
  HIP_EXTENSION: "Raise your leg behind you and hold.",
  SQUAT_KNEE_FLEXION: "Squat down as far as you can.",
  CERVICAL_ROTATION: "Turn your head to look over your right shoulder, and then turn your head to look over your left shoulder.",
  HIP_INTERNAL_ROTATION: "Standing on one leg, rotate your lifted leg to the outside.",
  HIP_EXTERNAL_ROTATION: "Standing on one leg, rotate your lifted leg to the inside.",
  SQUAT_HIP_FLEXION: "Squat down as far as you can.",
};

export const ASSESSMENT_GIF = {
  SHOULDER_ABDUCTION: "https://assets.rehabboost.com/images/assessment/v5/shoulder-abduction.gif",
  SHOULDER_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/shoulder-flexion-2.gif",
  SHOULDER_EXTENSION: "https://assets.rehabboost.com/images/assessment/v5/shoulder-extension-2.gif",
  ELBOW_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/elbow-flexion.gif",
  CERVICAL_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/cervical-flexion-zoomed.gif",
  CERVICAL_EXTENSION: "https://assets.rehabboost.com/images/assessment/v5/cervical-extension-zoomed.gif",
  CERVICAL_LATERAL_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/cervical-sidebend.gif",
  LUMBAR_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/lumbar-flexion.gif",
  LUMBAR_EXTENSION: "https://assets.rehabboost.com/images/assessment/v5/lumbar-extension.gif",
  LUMBAR_LATERAL_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/lumbar-lateral-flexion.gif",
  HIP_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/hip-flexion.gif",
  HIP_EXTENSION: "https://assets.rehabboost.com/images/assessment/v5/hip-extension.gif",
  SQUAT_KNEE_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/squat-hip-flexion.gif",
  CERVICAL_ROTATION: "https://assets.rehabboost.com/images/assessment/v5/cervical-rotation-zoomed.gif",
  HIP_INTERNAL_ROTATION: "https://assets.rehabboost.com/images/assessment/v6/hip-internal-rotation.gif",
  HIP_EXTERNAL_ROTATION: "https://assets.rehabboost.com/images/assessment/v6/hip-external-rotation.gif",
  SQUAT_HIP_FLEXION: "https://assets.rehabboost.com/images/assessment/v5/squat-hip-flexion.gif",
};

export const ASSESSMENT_SCORE = {
  SHOULDER_ABDUCTION: "180",
  SHOULDER_FLEXION: "180",
  SHOULDER_EXTENSION: "45",
  ELBOW_FLEXION: "150",
  CERVICAL_FLEXION: "80",
  CERVICAL_EXTENSION: "70",
  CERVICAL_LATERAL_FLEXION: "45",
  LUMBAR_FLEXION: "90",
  LUMBAR_EXTENSION: "40",
  LUMBAR_LATERAL_FLEXION: "45",
  HIP_FLEXION: "100",
  HIP_EXTENSION: "30",
  SQUAT_KNEE_FLEXION: "150",
  CERVICAL_ROTATION: "90",
  HIP_INTERNAL_ROTATION: "40",
  HIP_EXTERNAL_ROTATION: "60",
  SQUAT_HIP_FLEXION: "100",
  SIT_TO_STAND: "10"
};