/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */


export function dist(x1, y1, x2, y2) {
    const x = x2 - x1;
    const y = y2 - y1;
    const distance = Math.sqrt(x * x + y * y);
    return distance;
}

export function angle(p1x, p1y, p2x, p2y) {
    const angleRad = Math.atan2(p2y - p1y, p2x - p1x);
    return rad2deg(angleRad);
}

export function rad2deg(rad) {
    let result = rad * (180 / Math.PI) + 180;
    if (result > 360) {
        result = result - 360;
    }
    return result;
}

// Gets the angle (in radians) between three points
// vertex: Vertex point
// a: Point A
// b: Point B
// Returns: Angle in radians
export function getAngleBetweenThreePoints(vertex, a, b)
{
    const ab = getDistanceBetweenPoints(a, b);
    const va = getDistanceBetweenPoints(vertex, a);
    const vb = getDistanceBetweenPoints(vertex, b);

    const angle = Math.acos((Math.pow(va, 2) + Math.pow(vb, 2) - Math.pow(ab, 2)) / (2 * va * vb));
    return angle;
}

// Get distance (pixels) between two points
// returns distance (Pixels)
export function getDistanceBetweenPoints(a, b) {
    const bxMax = b.x - a.x;
    const byMay = b.y - a.y;
    const sumaDeCuadrados = Math.pow(bxMax, 2) + Math.pow(byMay, 2);
    const raiz = Math.sqrt(sumaDeCuadrados);
    const distance = parseInt(Math.trunc(raiz));
    return distance;
}

export function arePointsClose(point1X, point2X, tolerance) {
    return Math.abs(point1X - point2X) <= tolerance;
}

export function angleIsHorizontal(angle, tolerance) {
    const near0 = angle >= 0 && angle <= tolerance;
    const near360 = angle >= 360 - tolerance && angle <= 360;
    const min180 = 180 - tolerance;
    const max180 = 180 + tolerance;
    const near180 = angle >= min180 && angle <= max180;
    return near0 || near360 || near180;
}

export function angleIsVertical(angle, tolerance) {
    const min90 = 90 - tolerance;
    const max90 = 90 + tolerance;
    const near90 = angle >= min90 && angle <=max90;
    const min270 = 270 - tolerance;
    const max270 = 270 + tolerance;
    const near270 = angle >= min270 && angle <=max270;
    return near90 || near270
}

export function angle3Points(center, p1, p2) {
    const a1 = Math.atan2(p2.y - center.y, p2.x - center.x);
    const a2 = Math.atan2(p1.y - center.y, p1.x - center.x);
    return rad2deg(Math.abs(a1 - a2));
}

export function angleIsNearTo(angle, angleTarget, tolerance) {
    //Works between 0..180

    const lim1 = angleTarget - tolerance;
    const lim2 = angleTarget + tolerance;
    const near1 = angle >= lim1 && angle <= lim2;

    const lim3 = (180 - angleTarget) - tolerance;
    const lim4 = (180 - angleTarget) + tolerance;
    const near2 = angle >= lim3 && angle <= lim4;

    return near1 || near2;
}

export function calculateAngle(center, startPoint, endPoint, isClockwise = false) {
    // Calculate vectors between the points
    const vector1 = { dx: startPoint.x - center.x, dy: startPoint.y - center.y };
    const vector2 = { dx: endPoint.x - center.x, dy: endPoint.y - center.y };

    // Calculate the angle between the two vectors, in radians
    let angle1 = Math.atan2(vector1.dy, vector1.dx);
    let angle2 = Math.atan2(vector2.dy, vector2.dx);
    let angle = Math.abs(angle2 - angle1);

    // Adjust the angle based on the desired direction
    if (!isClockwise) {
        angle = 2 * Math.PI - angle;
    }

    // Convert the angle to degrees
    const angleInDegrees = angle * 180.0 / Math.PI;

    return angleInDegrees;
}
