/**
 * @license
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

/* INFO: Points top left is 1000 x and 0 Y. The 0,0 is the top right. Camera is fliped. Btm is > 1000*/

import * as params from './params';
import * as mathUtils from './math_utils';
import * as puppet from './puppet_utils';
import { isMobile } from './util';
import { time } from '@tensorflow/tfjs-core';

const thresholdStartPoint = 0.75;
const valueNotDefined = -1;

let holdStartPositionCount = 0;
let userInStartPositionForTest = false;
let userHasWaitedForStart = false;
let holdIntructionCount = 0;
let assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;

let lastActivityMessage = "";
let currentActivity = "";
let score = valueNotDefined;
let finishDate = undefined;

function setActivityMessage(event = "", currentScore = valueNotDefined){

    let secondsRemaining = calculateSecondsRemaining();

    if (currentScore > score) {
        score = currentScore;
    }

    const msg = {
        currentActivity: currentActivity,
        event: event,
        score: score,
        secondsRemaining: secondsRemaining
    };

    if (lastActivityMessage == msg) { return; }
    lastActivityMessage = msg;
    const activityMessage = document.getElementById('activity');
    if (activityMessage) { activityMessage.innerHTML = msg; }
    parent.postMessage({msgType: "activityMessage", msgBody: msg},"*");
}

function calculateSecondsRemaining() {
    if (finishDate == undefined) {
        return valueNotDefined;
    } else {
        const currentDate = new Date();
        const timeDifference = (finishDate.getTime() - currentDate.getTime()) / 1000;
        return Math.ceil(timeDifference); 
    }
}

function hasFinishedTimeExpected() {
    const secondsRemaining = calculateSecondsRemaining();
    return (secondsRemaining < 0);
}

function poseDetectionSTS(keypoints) {
    //Data obtained from start
    currentActivity = "SITTOSTAND";
    //End data obtained from start

    switch (assessmentPosition) {
        case params.ASSESSMENT_POSITION.NOT_STARTED:
            validateUserIsInFrame(keypoints);
            break;
        case params.ASSESSMENT_POSITION.SIT_TO_STAND:
            sitToStand(keypoints);
            break;
        case params.ASSESSMENT_POSITION.COMPLETE:
            score = valueNotDefined;
            userInStartPositionForTest = false;
            finishDate = undefined;
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            break;
    }
}

function validateUserIsInFrame(keypoints) {

    const headX = keypoints[params.KEYPOINTS.NOSE].x;
    const headY = keypoints[params.KEYPOINTS.NOSE].y;
    const rLegY = keypoints[params.KEYPOINTS.RIGHT_ANKLE].y;
    const lLegY = keypoints[params.KEYPOINTS.LEFT_ANKLE].y;
    const rhipX = keypoints[params.KEYPOINTS.RIGHT_HIP].x;
    const lhipX = keypoints[params.KEYPOINTS.LEFT_HIP].x;

    const rAnkleScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;
    const lAnkleScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;
    const headScore = keypoints[params.KEYPOINTS.NOSE].score;
    const rHandScore = keypoints[params.KEYPOINTS.RIGHT_WRIST].score;
    const lHandScore = keypoints[params.KEYPOINTS.LEFT_WRIST].score;
    const rHipScore = keypoints[params.KEYPOINTS.RIGHT_HIP].score;
    const lHipScore = keypoints[params.KEYPOINTS.LEFT_HIP].score;
    const verifyBodyInFrame = rAnkleScore > thresholdStartPoint && lAnkleScore > thresholdStartPoint && headScore > thresholdStartPoint && rHandScore > thresholdStartPoint && lHandScore > thresholdStartPoint && rHipScore > thresholdStartPoint && lHipScore > thresholdStartPoint;

    const minHip = Math.min(lhipX, rhipX);
    const maxHip = Math.max(lhipX, rhipX);
    const headOk = headX >= minHip && headX <= maxHip && headY > 30;
    const tooClose = Math.max(lLegY - headY, rLegY - headY) > 900;

    if (!verifyBodyInFrame || !headOk) {
        setActivityMessage("not_in_frame");
    } else if (tooClose) {
        setActivityMessage("body_too_close");
    } else {
        setActivityMessage("in_frame");
        assessmentPosition = params.ASSESSMENT_POSITION.SIT_TO_STAND;
    }
}

function detectInPositionToStartTest(keypoints) {
    const lAnkleScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;
    const rAnkleScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;

    const nose = keypoints[params.KEYPOINTS.NOSE];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const leftAngle = Math.abs(180 - mathUtils.calculateAngle(lKnee, lHip, lAnkle, false));
    const rightAngle = Math.abs(180 - mathUtils.calculateAngle(rKnee, rHip, rAnkle, true));

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x)) * 2;

    // function checkArmsCloseness() {
    //     const areWristCloseToShoulders = mathUtils.arePointsClose(rWrist.x, rShoulder.x, uow*3) && mathUtils.arePointsClose(lWrist.x, lShoulder.x, uow*3)
    //     return areWristCloseToShoulders
    // }

    const validateScoring = lAnkleScore > thresholdStartPoint || rAnkleScore > thresholdStartPoint;
    if (!validateScoring) {
        setActivityMessage("not_in_frame");
        holdStartPositionCount = 0;
    } else {
        setActivityMessage("in_frame");
    }

    // if (checkArmsCloseness()) {
        let isSittedToTheRight = puppet.isFacingRight(nose, lShoulder, rShoulder) && puppet.leftIsBent(leftAngle)
        let isSittedToTheLeft = puppet.isFacingLeft(nose, lShoulder, rShoulder) && puppet.rightIsBent(rightAngle)
        if (isSittedToTheRight || isSittedToTheLeft) {
            holdStartPositionCount++;
        } else {
            holdStartPositionCount = 0;
        }
    // }

    const timeToHold = isMobile() ? 70 : 100;
    let assessmentStartCondition = holdStartPositionCount > timeToHold;
    if (assessmentStartCondition) {
        holdStartPositionCount = 0;
        userInStartPositionForTest = true;
        setActivityMessage("in_position");
    }
}

function waitInPosition() {
    const timeExpectedUntilStart = 5;
    if (finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeExpectedUntilStart);
    }
    if (hasFinishedTimeExpected()) {
        finishDate = undefined;
        setActivityMessage("started", 0); // Set initial score to 0.
        userHasWaitedForStart = true;
    } else {
        setActivityMessage("in_position");
        holdIntructionCount++;
    }
}

//----------------------- SIT TO STAND - START
const timeLimitInSeconds = 30;
var repsLocked = false;

function sitToStand(keypoints) {

    //VALUES
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const nose = keypoints[params.KEYPOINTS.NOSE];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    const lElbow = keypoints[params.KEYPOINTS.LEFT_ELBOW];
    const rElbow = keypoints[params.KEYPOINTS.RIGHT_ELBOW];

    const leftAngle = Math.abs(180 - mathUtils.calculateAngle(lKnee, lHip, lAnkle, false));
    const rightAngle = Math.abs(180 - mathUtils.calculateAngle(rKnee, rHip, rAnkle, true));

    function isFacingRight() {
        return puppet.isFacingRight(nose, lShoulder, rShoulder);
    }

    function isFacingLeft() {
        return puppet.isFacingLeft(nose, lShoulder, rShoulder)
    }

    function checkArmsCloseness() {
        const areWristCloseToShoulders = mathUtils.arePointsClose(rWrist.x, rShoulder.x, uow*3) && mathUtils.arePointsClose(lWrist.x, lShoulder.x, uow*3)
        return areWristCloseToShoulders
    }

    function personIsSitted() {
        if (isFacingRight()) {
            return puppet.leftIsBent(leftAngle);
        } else if (isFacingLeft()) {
            return puppet.rightIsBent(rightAngle);
        } else {
            return false;
        }
    }

    function personIsStanding() {
        if (isFacingRight()) {
            return puppet.leftIsStraight(leftAngle);
        } else if (isFacingLeft()) {
            return puppet.rightIsStraight(rightAngle);
        } else {
            return false;
        }
    }

    function isDoingTest() {
        let kneeBetweenHipAndWrist = false;
        let ankleBetweenHipAndKnee = false;
        if (isFacingRight()) {
            kneeBetweenHipAndWrist = puppet.bodyPartIsBetweenX(lKnee, lHip, lWrist);
            ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(lAnkle, lHip, lKnee);
        }
        if (isFacingLeft()) {
            kneeBetweenHipAndWrist = puppet.bodyPartIsBetweenX(rKnee, rHip, rWrist);
            ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(rAnkle, rHip, rKnee);
        }
        return !isFrontal && kneeBetweenHipAndWrist && ankleBetweenHipAndKnee;
    }

    function checkFallCriteria() {

    }

    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    if (!userHasWaitedForStart) {
        waitInPosition();
        return;
    }
    
    // Validate if user is still in frame
        // (Consider 'notInPosition' func in iOS app)
        // If not --> define what to do (restart, abort, finish...)

    if (finishDate == undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeLimitInSeconds);
    }
   
    if (hasFinishedTimeExpected()) {
        finishDate = undefined;
        setActivityMessage("finished", score);
        assessmentPosition = params.ASSESSMENT_POSITION.COMPLETE;
        return;
    }

    if (isDoingTest()) {
        if (repsLocked) {
            if (personIsSitted()) {
                repsLocked = false;
            }
        } else {
            if (personIsStanding()) {
                score ++;
                repsLocked = true;
            }
        }
    }
    setActivityMessage("started", score);
}

module.exports = poseDetectionSTS;
