import '@tensorflow/tfjs-backend-webgl';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import 'regenerator-runtime/runtime';

import * as mpPose from '@mediapipe/pose';
import * as posedetection from '@tensorflow-models/pose-detection';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
        tfjsWasm.version_wasm}/dist/`);

import {Camera} from '../camera';
import {setupDatGui} from '../option_panel';
import {STATE} from '../params';
import {setupStats} from '../stats_panel';
import {setBackendAndEnvFlags} from '../util';

let detector, camera, stats;
let startInferenceTime, numInferences = 0;
let inferenceTimeSum = 0, lastPanelUpdate = 0;
let rafId;
const restartDetection = require('../pose_detection');

async function createDetector() {
    switch (STATE.model) {
      case posedetection.SupportedModels.PoseNet:
        return posedetection.createDetector(STATE.model, {
          quantBytes: 4,
          architecture: 'MobileNetV1',
          outputStride: 16,
          inputResolution: {width: 500, height: 500},
          multiplier: 0.75
        });
      case posedetection.SupportedModels.BlazePose:
        const runtime = STATE.backend.split('-')[0];
        if (runtime === 'mediapipe') {
          return posedetection.createDetector(STATE.model, {
            runtime,
            modelType: STATE.modelConfig.type,
            solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
          });
        } else if (runtime === 'tfjs') {
          return posedetection.createDetector(
              STATE.model, {runtime, modelType: STATE.modelConfig.type});
        }
      case posedetection.SupportedModels.MoveNet:
        let modelType;
        if (STATE.modelConfig.type == 'lightning') {
          modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
        } else if (STATE.modelConfig.type == 'thunder') {
          modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
        } else if (STATE.modelConfig.type == 'multipose') {
          modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
        }
        const modelConfig = {modelType};
  
        if (STATE.modelConfig.customModel !== '') {
          modelConfig.modelUrl = STATE.modelConfig.customModel;
        }
        if (STATE.modelConfig.type === 'multipose') {
          modelConfig.enableTracking = STATE.modelConfig.enableTracking;
        }
        return posedetection.createDetector(STATE.model, modelConfig);
    }
}
  
async function checkGuiUpdate() {
    if (STATE.isTargetFPSChanged || STATE.isSizeOptionChanged) {
        camera = await Camera.setupCamera(STATE.camera);
        STATE.isTargetFPSChanged = false;
        STATE.isSizeOptionChanged = false;
    }

    if (STATE.isModelChanged || STATE.isFlagChanged || STATE.isBackendChanged) {
        STATE.isModelChanged = true;

        window.cancelAnimationFrame(rafId);

        if (detector != null) {
        detector.dispose();
        }

        if (STATE.isFlagChanged || STATE.isBackendChanged) {
        await setBackendAndEnvFlags(STATE.flags, STATE.backend);
        }

        try {
        detector = await createDetector(STATE.model);
        } catch (error) {
        detector = null;
        alert(error);
        }

        STATE.isFlagChanged = false;
        STATE.isBackendChanged = false;
        STATE.isModelChanged = false;
    }
}
  
function beginEstimatePosesStats() {
    startInferenceTime = (performance || Date).now();
}
  
function endEstimatePosesStats() {
    const endInferenceTime = (performance || Date).now();
    inferenceTimeSum += endInferenceTime - startInferenceTime;
    ++numInferences;

    const panelUpdateMilliseconds = 1000;
    if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
        const averageInferenceTime = inferenceTimeSum / numInferences;
        inferenceTimeSum = 0;
        numInferences = 0;
        stats.customFpsPanel.update(
            1000.0 / averageInferenceTime, 120 /* maxValue */);
        lastPanelUpdate = endInferenceTime;
    }
}
  
async function renderResult() {
    if (camera.video.readyState < 2) {
        await new Promise((resolve) => {
        camera.video.onloadeddata = () => {
            resolve(video);
        };
        });
    }

    let poses = null;

    // Detector can be null if initialization failed (for example when loading
    // from a URL that does not exist).
    if (detector != null) {
        // FPS only counts the time it takes to finish estimatePoses.
        beginEstimatePosesStats();

        // Detectors can throw errors, for example when using custom URLs that
        // contain a model that doesn't provide the expected output.
        try {
        poses = await detector.estimatePoses(
            camera.video,
            {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});
        } catch (error) {
        detector.dispose();
        detector = null;
        alert(error);
        }

        endEstimatePosesStats();
    }

    camera.drawCtx();

    // The null check makes sure the UI is not in the middle of changing to a
    // different model. If during model change, the result is from an old model,
    // which shouldn't be rendered.
    if (poses && poses.length > 0 && !STATE.isModelChanged) {
        camera.drawResults(poses);
    }
}
  
async function renderPrediction() {
    await checkGuiUpdate();

    if (!STATE.isModelChanged) {
        await renderResult();
    }

    rafId = requestAnimationFrame(renderPrediction);
};

async function setUp(){
    // Gui content will change depending on which model is in the query string.
    //const urlParams = new URLSearchParams(window.location.search);
    const urlParams = new URLSearchParams('?model=blazepose');

    if (!urlParams.has('model')) {
      alert('Cannot find model in the query string. Use /?model=blazepose');
      return;
    }

    await setupDatGui(urlParams);

    stats = setupStats();

    camera = await Camera.setupCamera(STATE.camera);

    await setBackendAndEnvFlags(STATE.flags, STATE.backend);

    detector = await createDetector();

    renderPrediction();
}



const Home = () => {
    setUp();
    return (<>
        <div id="stats"></div>
        <div id="main">
            <div className="container">
                <div className="canvas-wrapper">
                    <div id="status" className="status-text"></div>
                    <div id="score" className="status-text"></div>
                    <div id="result" className="status-text"></div>
                    <div id="currentTest" hidden className="status-text"></div>
                    <canvas id="output"></canvas>
                    <video id="video" playsInline muted style={{transform: 'scaleX(-1)', visibility: 'hidden', width: 'auto', height: 'auto', WebkitTransform: 'scaleX(-1)'}}>
                    </video>
                    <div id="notice">
                        <h2>CONFIDENTIAL AND PROPRIETARY CONTENT</h2>
                        <p>The use of this platform is for testing purposes by Rehab Boost and authorized users only.</p>
                        <p><small>v0.0.3 - for chromium browsers</small></p>
                    </div>
                </div>
                <div id="scatter-gl-container"></div>
            </div>
        </div>
    </>);
};

export default Home;
