/**
 * @license
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

/* INFO: Points top left is 1000 x and 0 Y. The 0,0 is the top right. Camera is fliped. Btm is > 1000*/

import * as params from './params';
import * as mathUtils from './math_utils';
import * as puppet from './puppet_utils';
import { isMobile } from './util';

const thresholdStartPoint = 0.75;
const valueNotDefined = -1;

let holdStartPositionCount = 0;
let userInStartPositionForTest = false;
let holdIntructionCount = 0;
let holdUserOrientationCount = 0;
let assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;

let bestLeftScore = valueNotDefined;
let bestRightScore = valueNotDefined;
let bestTotalScore = valueNotDefined;

let assessmentResults = [];

//TEST ENUM
let minimumValidAngleForAssessment = 0;
let maxScoreForAssessment = 0;

var lastStatusMessage = "";
var lastScoreMessage = "";
var lastResultMessage = "";
var lastCurrentTestMessage = "";
var lastUserMessage = "";

let userInAssessment = "false";
let userOrientation = "frontal";

function setStatusMessage(status, notification = ""){

    const msg = JSON.stringify({
        status: status,
        notification: notification
    });

    if (lastStatusMessage == msg) { return; }
    lastStatusMessage = msg;

    const statusMessage = document.getElementById('status');
    if (statusMessage) { statusMessage.innerHTML = msg; }
    parent.postMessage({msgType: "statusMessage", msgBody: msg},"*");
}

function setScoreMessage(msg){

    if (lastScoreMessage == msg) { return; }

    if (msg == "") {
        msg = JSON.stringify({
            leftScore: -1,
            rightScore: -1,
            totalScore: -1
        });
    }

    lastScoreMessage = msg;

    const scoreMessage = document.getElementById('score');
    if (scoreMessage) { scoreMessage.innerHTML = msg; }
    parent.postMessage({msgType: "scoreMessage", msgBody: msg},"*");
}

function setResultMessage(msg){

    if (lastResultMessage == msg) { return; }
    lastStatusMessage = msg;

    const resultMessage = document.getElementById('result');
    if (resultMessage) { resultMessage.innerHTML = msg; }
    parent.postMessage({msgType: "resultMessage", msgBody: msg},"*");
}

function setCurrentTestMessage(name, gif, score){

    if (score == "") {
        score = -1;
    }
    
    const msg = JSON.stringify({
        name: name,
        gif: gif,
        score: score
    });

    if (lastCurrentTestMessage == msg) { return; }
    lastCurrentTestMessage = msg;

    const currentTestMessage = document.getElementById('currentTest');
    if (currentTestMessage) { currentTestMessage.innerHTML = msg; }
    parent.postMessage({msgType: "currentTestMessage", msgBody: msg},"*");
}

function setUserMessage(orientation = "frontal"){

    const msg = JSON.stringify({
        orientation: orientation,
        inAssessment: userInAssessment
    });

    if (lastUserMessage == msg) { return; }
    lastUserMessage = msg;

    const userMessage = document.getElementById('user');
    if (userMessage) { userMessage.innerHTML = msg; }
    parent.postMessage({msgType: "userMessage", msgBody: msg},"*");
}

function restartDetection() {
    userInStartPositionForTest = false;
    holdStartPositionCount = 0;
    setStatusMessage(params.ASSESSMENT_STATUS.NOT_STARTED);
    setScoreMessage("");
    setResultMessage("");
    assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;
    assessmentResults = [];
    cleanTestValues();
}

function cleanTestValues() {
    minimumValidAngleForAssessment = 0;
    maxScoreForAssessment = 0;
    bestLeftScore = valueNotDefined;
    bestRightScore = valueNotDefined;
    bestTotalScore = valueNotDefined;
}

function recordAssessment(name, leftScore, rightScore, totalScore, normalScore) {
    const assessment = {
        name,
        leftScore,
        rightScore,
        totalScore, 
        normalScore
    };

    assessmentResults.push(assessment);
}

function poseDetection(keypoints) {

    verifyUser(keypoints);

    switch (assessmentPosition) {
        case params.ASSESSMENT_POSITION.NOT_STARTED:
            validateUserIsInFrame(keypoints);
            break;
        case params.ASSESSMENT_POSITION.SHOULDER_ABDUCTION:
            shoulderAbductionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.SHOULDER_FLEXION:
            shoulderFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.SHOULDER_EXTENSION:
            shoulderExtensionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.ELBOW_FLEXION:
            elbowFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.CERVICAL_FLEXION:
            cervicalFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.CERVICAL_EXTENSION:
            cervicalExtensionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.CERVICAL_LATERAL_FLEXION:
            cervicalLateralFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.LUMBAR_FLEXION:
            lumbarFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.LUMBAR_EXTENSION:
            lumbarExtensionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.LUMBAR_LATERAL_FLEXION:
            lumbarLateralFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.HIP_FLEXION:
            hipFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.HIP_EXTENSION:
            hipExtensionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.SQUAT_KNEE_FLEXION:
            squatKneeFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.CERVICAL_ROTATION:
            cervicalRotationTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.HIP_INTERNAL_ROTATION:
            hipInternalRotationTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.HIP_EXTERNAL_ROTATION:
            hipExternalRotationTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.SQUAT_HIP_FLEXION:
            squatHipFlexionTest(keypoints);
            break;
        case params.ASSESSMENT_POSITION.COMPLETE:
            const assessmentResultJson = JSON.stringify(assessmentResults);
            setStatusMessage(params.ASSESSMENT_STATUS.END);
            setScoreMessage("");
            setResultMessage(assessmentResultJson);
            setCurrentTestMessage("", "", "");
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            break;
    }
}

function verifyUser(keypoints) {
    const timeToHold = isMobile() ? 25 : 60;
    if (holdUserOrientationCount > timeToHold) {
        const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
        const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
        const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);
        const isRight = puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder);
        const isLeft = puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder);

        if (isFrontal) {
            userOrientation = "frontal";
        } else if (isRight) {
            userOrientation = "right";
        } else if (isLeft) {
            userOrientation = "left";
        }
        holdUserOrientationCount = 0;

    } else {
        holdUserOrientationCount++;
    }

    setUserMessage(userOrientation, userInAssessment);
}

function updateScoreMax(leftScore, rightScore, totalScore) {
    bestLeftScore = (leftScore > bestLeftScore) ? leftScore : bestLeftScore;
    bestRightScore = (rightScore > bestRightScore) ? rightScore : bestRightScore;
    bestTotalScore = (totalScore > bestTotalScore) ? totalScore : bestTotalScore;

    //Shouldnt be bigger than max value
    bestLeftScore = (bestLeftScore > maxScoreForAssessment) ? maxScoreForAssessment : bestLeftScore;
    bestRightScore = (bestRightScore > maxScoreForAssessment) ? maxScoreForAssessment : bestRightScore;
    bestTotalScore = (bestTotalScore > maxScoreForAssessment) ? maxScoreForAssessment : bestTotalScore;

    setScoreMessage(JSON.stringify({
        leftScore: bestLeftScore,
        rightScore: bestRightScore,
        totalScore: bestTotalScore
    }));
}

function validateUserIsInFrame(keypoints) {
    setStatusMessage(params.ASSESSMENT_STATUS.NOT_STARTED);
    const headX = keypoints[params.KEYPOINTS.NOSE].x;
    const headY = keypoints[params.KEYPOINTS.NOSE].y;
    const rLegY = keypoints[params.KEYPOINTS.RIGHT_ANKLE].y;
    const lLegY = keypoints[params.KEYPOINTS.LEFT_ANKLE].y;
    const rhipX = keypoints[params.KEYPOINTS.RIGHT_HIP].x;
    const lhipX = keypoints[params.KEYPOINTS.LEFT_HIP].x;

    const rLegScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;
    const lLegScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;
    const headScore = keypoints[params.KEYPOINTS.NOSE].score;
    const rHandScore = keypoints[params.KEYPOINTS.RIGHT_WRIST].score;
    const lHandScore = keypoints[params.KEYPOINTS.LEFT_WRIST].score;
    const rHipScore = keypoints[params.KEYPOINTS.RIGHT_HIP].score;
    const lHipScore = keypoints[params.KEYPOINTS.LEFT_HIP].score;
    const verifyBodyInFrame = rLegScore > thresholdStartPoint && lLegScore > thresholdStartPoint && headScore > thresholdStartPoint && rHandScore > thresholdStartPoint && lHandScore > thresholdStartPoint && rHipScore > thresholdStartPoint && lHipScore > thresholdStartPoint;

    const minHip = Math.min(lhipX, rhipX);
    const maxHip = Math.max(lhipX, rhipX);
    const headOk = headX >= minHip && headX <= maxHip && headY > 30;
    const tooClose = Math.max(lLegY - headY, rLegY - headY) > 900;

    if (!verifyBodyInFrame) {
        setStatusMessage(params.ASSESSMENT_STATUS.NOT_STARTED, params.ASSESSMENT_NOTIFICATIONS.BODY_NOT_IN_FRAME);
    } else if (tooClose) {
        setStatusMessage(params.ASSESSMENT_STATUS.NOT_STARTED, params.ASSESSMENT_NOTIFICATIONS.BODY_TOO_CLOSE);
    } else if (headOk) {
        setStatusMessage(params.ASSESSMENT_STATUS.SET_UP);
        assessmentPosition = params.ASSESSMENT_POSITION.SHOULDER_ABDUCTION;
    }
}

function detectInPositionToStartTest(keypoints) {
    setStatusMessage(params.ASSESSMENT_STATUS.SET_UP);
    const lWristX = keypoints[params.KEYPOINTS.LEFT_WRIST].x;
    const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;

    const rWristX = keypoints[params.KEYPOINTS.RIGHT_WRIST].x;
    const rWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;

    const lHipY = keypoints[params.KEYPOINTS.LEFT_HIP].y;
    const rHipY = keypoints[params.KEYPOINTS.RIGHT_HIP].y;
    const lHipX = keypoints[params.KEYPOINTS.LEFT_HIP].x;
    const rHipX = keypoints[params.KEYPOINTS.RIGHT_HIP].x;

    const lAnkleScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;
    const rAnkleScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;

    const lShoulderY = keypoints[params.KEYPOINTS.LEFT_SHOULDER].y;
    const rShoulderY = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].y;

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x)) * 2;
    const largeTolerance = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_SHOULDER].x - keypoints[params.KEYPOINTS.LEFT_SHOULDER].x));

    const validateScoring = lAnkleScore > thresholdStartPoint && rAnkleScore > thresholdStartPoint;
    if (!validateScoring) {
        setStatusMessage(params.ASSESSMENT_STATUS.SET_UP, params.ASSESSMENT_NOTIFICATIONS.BODY_NOT_IN_FRAME);
        holdStartPositionCount = 0;
    }

    const inVerticalPosition = (lWristY >= ((lShoulderY + lHipY) / 2)) && (rWristY >= ((rShoulderY + rHipY) / 2));
    
    const areWristCloseToHipY = mathUtils.arePointsClose(rWristY, rHipY, uow) && mathUtils.arePointsClose(lWristY, lHipY, uow);    
    const areWristInSameArea = mathUtils.arePointsClose(rWristX, rHipX, largeTolerance) && mathUtils.arePointsClose(lWristX, lHipX, largeTolerance);

    if (inVerticalPosition && areWristCloseToHipY) {
        holdStartPositionCount++;
    } else {
        holdStartPositionCount = 0;
    }

    if (areWristInSameArea) {
        holdStartPositionCount++;
    }

    const timeToHold = isMobile() ? 70 : 100;
    let assessmentStartCondition = holdStartPositionCount > timeToHold;
    if (assessmentStartCondition) {
        setStatusMessage(params.ASSESSMENT_STATUS.SET_UP, params.ASSESSMENT_NOTIFICATIONS.GOOD_JOB);
        holdStartPositionCount = 0;
        userInStartPositionForTest = true;
    }
}

function startOfTestAssessmentSetUp(minAngle, maxScore, isTotal, name, notification, gif, assScore) {

    minimumValidAngleForAssessment = minAngle;
    maxScoreForAssessment = maxScore;

    if (!isTotal) {
        updateScoreMax(0, 0, valueNotDefined);
    } else {
        updateScoreMax(valueNotDefined, valueNotDefined, 0);
    }

    setCurrentTestMessage(name, gif, assScore);

    const timeToHold = isMobile() ? 180 : 250;
    const hasPassedEnoughTimeForInstruction = holdIntructionCount > timeToHold;
    if (hasPassedEnoughTimeForInstruction) {
        userInAssessment = "true";
    } else {
        userInAssessment = "false";
        holdIntructionCount++;
    }
    setStatusMessage(name, notification);
    setUserMessage(userOrientation, userInAssessment);

    return hasPassedEnoughTimeForInstruction;
}

function endOfTestAssessment(nextPosition, normalScore) {
    recordAssessment(assessmentPosition, bestLeftScore, bestRightScore, bestTotalScore, normalScore);
    setScoreMessage("");
    setCurrentTestMessage("", "", "");
    cleanTestValues();
    assessmentPosition = nextPosition;
    userInStartPositionForTest = false;
    holdIntructionCount = 0
}

function shoulderAbductionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        160,
        180,
        false,
        params.ASSESSMENT_STATUS.SHOULDER_ABDUCTION,
        params.ASSESSMENT_NOTIFICATIONS.SHOULDER_ABDUCTION,
        params.ASSESSMENT_GIF.SHOULDER_ABDUCTION,
        params.ASSESSMENT_SCORE.SHOULDER_ABDUCTION
    );
    
    if (!enableToStart) {
        return;
    }
    
    //VALUES
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    
    const lElbow = keypoints[params.KEYPOINTS.LEFT_ELBOW];
    const rElbow = keypoints[params.KEYPOINTS.RIGHT_ELBOW];
    
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
   
    //CHECK FINISH
    const anyArmAboveShoulder = puppet.bodyPartIsHigher(lWrist, lShoulder, uow) || puppet.bodyPartIsHigher(rWrist, rShoulder, uow);
    const areWristCloseToHip = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow);    

    if (hasExerciseFinished() && areWristCloseToHip && !anyArmAboveShoulder) 
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.SHOULDER_FLEXION, params.ASSESSMENT_SCORE.SHOULDER_ABDUCTION);
        return;
    }

    //TESTING
    const leftAngle = mathUtils.angle3Points(lElbow, lShoulder, lWrist);
    const rightAngle = mathUtils.angle3Points(rElbow, rShoulder, rWrist);
    const wristsAboveHips = puppet.bodyPartIsHigher(lWrist, lHip, uow) && puppet.bodyPartIsHigher(rWrist, rHip, uow)
    const isDoingTest = puppet.areStraight(leftAngle, rightAngle, 45) && wristsAboveHips;
    
    if (isDoingTest)
    {
        let leftEnd = (lWrist.x === 0 && lWrist.y === 0) ? lElbow : lWrist;
        let rightEnd = (rWrist.x === 0 && rWrist.y === 0) ? rElbow : rWrist;
        
        let angleLeft = 180 - (mathUtils.angle(lShoulder.x, lShoulder.y, leftEnd.x, leftEnd.y) - 90);
        let angleRight = mathUtils.angle(rightEnd.x, rightEnd.y, rShoulder.x, rShoulder.y) - 90;
        updateScoreMax(Math.round(angleLeft), Math.round(angleRight), valueNotDefined);
    }
}

function shoulderFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }
    
    let enableToStart = startOfTestAssessmentSetUp(
        160,
        180,
        false,
        params.ASSESSMENT_STATUS.SHOULDER_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.SHOULDER_FLEXION,
        params.ASSESSMENT_GIF.SHOULDER_FLEXION,
        params.ASSESSMENT_SCORE.SHOULDER_FLEXION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    
    const lElbow = keypoints[params.KEYPOINTS.LEFT_ELBOW];
    const rElbow = keypoints[params.KEYPOINTS.RIGHT_ELBOW];
    
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    //CHECK FINISH
    const anyArmAboveShoulder = puppet.bodyPartIsHigher(lWrist, lShoulder, uow) || puppet.bodyPartIsHigher(rWrist, rShoulder, uow);
    const areWristCloseToHipX = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow);    
    const areWristCloseToHipY = mathUtils.arePointsClose(rWrist.y, rHip.y, uow) && mathUtils.arePointsClose(lWrist.y, lHip.y, uow);    
    
    if (hasExerciseFinished() && areWristCloseToHipX && areWristCloseToHipY && !anyArmAboveShoulder && isFrontal)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.SHOULDER_EXTENSION, params.ASSESSMENT_SCORE.SHOULDER_FLEXION);
        return;
    }
    
    //TESTING
    let leftEnd = (lWrist.x === 0 && lWrist.y === 0) ? lElbow : lWrist;
    let rightEnd = (rWrist.x === 0 && rWrist.y === 0) ? rElbow : rWrist;
    let isDoingTest = !isFrontal;

    if (isDoingTest) {
        const leftArmAngle = Math.abs(
            mathUtils.angle(leftEnd.x, leftEnd.y, lShoulder.x, lShoulder.y) - 90
        );
        
        const rightArmAngle = Math.abs(
            180 - (mathUtils.angle(rShoulder.x, rShoulder.y, rightEnd.x, rightEnd.y) - 90)
        );
        
        if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
            updateScoreMax(Math.round(leftArmAngle), valueNotDefined, valueNotDefined);
        }

        if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
            updateScoreMax(valueNotDefined, Math.round(rightArmAngle), valueNotDefined);
        }
    }
}

function shoulderExtensionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        36,
        45,
        false,
        params.ASSESSMENT_STATUS.SHOULDER_EXTENSION,
        params.ASSESSMENT_NOTIFICATIONS.SHOULDER_EXTENSION,
        params.ASSESSMENT_GIF.SHOULDER_EXTENSION,
        params.ASSESSMENT_SCORE.SHOULDER_EXTENSION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    
    const lElbow = keypoints[params.KEYPOINTS.LEFT_ELBOW];
    const rElbow = keypoints[params.KEYPOINTS.RIGHT_ELBOW];
    
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    //CHECK FINISH
    const anyArmAboveShoulder = puppet.bodyPartIsHigher(lWrist, lShoulder, uow) || puppet.bodyPartIsHigher(rWrist, rShoulder, uow);
    const areWristCloseToHipX = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow); 
    const areWristCloseToHipY = mathUtils.arePointsClose(rWrist.y, rHip.y, uow) && mathUtils.arePointsClose(lWrist.y, lHip.y, uow);

    if (hasExerciseFinished() && !anyArmAboveShoulder && areWristCloseToHipX && areWristCloseToHipY)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.ELBOW_FLEXION, params.ASSESSMENT_SCORE.SHOULDER_EXTENSION);
        return;
    }
    
    //TESTING
    let leftEnd = (lWrist.x === 0 && lWrist.y === 0) ? lElbow : lWrist;
    let rightEnd = (rWrist.x === 0 && rWrist.y === 0) ? rElbow : rWrist;
    let isDoingTest = !isFrontal;

    if (isDoingTest) {

        const leftArmAngle = Math.abs(
           Math.min(mathUtils.angle(leftEnd.x, leftEnd.y, lShoulder.x, lShoulder.y) - 90, 0)
           //Min is used so it doesnt count Flexion (ang > 0)
        );
        
        const rightArmAngle = Math.abs(
           Math.min(180 - (mathUtils.angle(rShoulder.x, rShoulder.y, rightEnd.x, rightEnd.y) - 90), 0)
        );
        
        if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
           updateScoreMax(Math.round(leftArmAngle), valueNotDefined, valueNotDefined);
        }

        if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, Math.round(rightArmAngle), valueNotDefined);
        }
    }
}

function elbowFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        120,
        170,
        false,
        params.ASSESSMENT_STATUS.ELBOW_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.ELBOW_FLEXION,
        params.ASSESSMENT_GIF.ELBOW_FLEXION,
        params.ASSESSMENT_SCORE.ELBOW_FLEXION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    
    const lElbow = keypoints[params.KEYPOINTS.LEFT_ELBOW];
    const rElbow = keypoints[params.KEYPOINTS.RIGHT_ELBOW];

    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    //CHECK FINISH
    const anyArmAboveShoulder = puppet.bodyPartIsHigher(lWrist, lShoulder, uow) || puppet.bodyPartIsHigher(rWrist, rShoulder, uow);
    const areWristCloseToHipX = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow); 
    const areWristCloseToHipY = mathUtils.arePointsClose(rWrist.y, rHip.y, uow) && mathUtils.arePointsClose(lWrist.y, lHip.y, uow);

    if (hasExerciseFinished() && !anyArmAboveShoulder && areWristCloseToHipX && areWristCloseToHipY)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.CERVICAL_FLEXION, params.ASSESSMENT_SCORE.ELBOW_FLEXION);
        return;
    }
    
    //TESTING
    let isDoingTest = !isFrontal;

    if (isDoingTest) {

        const leftArmAngle = Math.abs(
           mathUtils.angle(lWrist.x, lWrist.y, lElbow.x, lElbow.y) - 90
        );
        
        const rightArmAngle = Math.abs(
           180 - (mathUtils.angle(rElbow.x, rElbow.y, rWrist.x, rWrist.y) - 90)
        );
        
        if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
           updateScoreMax(Math.round(leftArmAngle), valueNotDefined, valueNotDefined);
        }

        if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, Math.round(rightArmAngle), valueNotDefined);
        }
    }
}

function cervicalFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        64,
        120,
        true,
        params.ASSESSMENT_STATUS.CERVICAL_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.CERVICAL_FLEXION,
        params.ASSESSMENT_GIF.CERVICAL_FLEXION,
        params.ASSESSMENT_SCORE.CERVICAL_FLEXION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];

    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const nose = keypoints[params.KEYPOINTS.NOSE];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    const middlePointShoulder = {
        x: (lShoulder.x + rShoulder.x) / 2,
        y: (lShoulder.y + rShoulder.y) / 2
    };

    //CHECK FINISH
    const areWristCloseToHipX = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow); 
    const areWristCloseToHipY = mathUtils.arePointsClose(rWrist.y, rHip.y, uow) && mathUtils.arePointsClose(lWrist.y, lHip.y, uow);

    if (hasExerciseFinished() && areWristCloseToHipX && areWristCloseToHipY && isFrontal)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.CERVICAL_EXTENSION, params.ASSESSMENT_SCORE.CERVICAL_FLEXION);
        return;
    }
    
    //TESTING
    let isDoingTest = !isFrontal;

    if (isDoingTest) {

        const leftAngle = Math.abs(
           mathUtils.angle(middlePointShoulder.x, middlePointShoulder.y, nose.x, nose.y) - 90
        );
        
        const rightAngle = Math.abs(
           180 - (mathUtils.angle(nose.x, nose.y, middlePointShoulder.x, middlePointShoulder.y) - 90)
        );
        
        if (leftAngle < 10 && rightAngle < 10) {
            return;
        }

        if (puppet.isFacingRight(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(leftAngle));
        }

        if (puppet.isFacingLeft(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(rightAngle));
        }
    }
}

function cervicalExtensionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        56,
        120,
        true,
        params.ASSESSMENT_STATUS.CERVICAL_EXTENSION,
        params.ASSESSMENT_NOTIFICATIONS.CERVICAL_EXTENSION,
        params.ASSESSMENT_GIF.CERVICAL_EXTENSION,
        params.ASSESSMENT_SCORE.CERVICAL_EXTENSION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];

    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const nose = keypoints[params.KEYPOINTS.NOSE];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    const middlePointShoulder = {
        x: (lShoulder.x + rShoulder.x) / 2,
        y: (lShoulder.y + rShoulder.y) / 2
    };

    //CHECK FINISH
    const areWristCloseToHipX = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow); 
    const areWristCloseToHipY = mathUtils.arePointsClose(rWrist.y, rHip.y, uow) && mathUtils.arePointsClose(lWrist.y, lHip.y, uow);

    if (hasExerciseFinished() && areWristCloseToHipX && areWristCloseToHipY && isFrontal)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.CERVICAL_LATERAL_FLEXION, params.ASSESSMENT_SCORE.CERVICAL_EXTENSION);
        return;
    }
    
    //TESTING
    let isDoingTest = !isFrontal;

    if (isDoingTest) {

        const leftAngle = Math.abs(
            Math.max(mathUtils.angle(nose.x, nose.y, middlePointShoulder.x, middlePointShoulder.y) - 220, 0)
        ) * 1.85;
        
        const rightAngle = Math.abs(
            Math.max(50 - (mathUtils.angle(middlePointShoulder.x, middlePointShoulder.y, nose.x, nose.y) - 90), 0)
        ) * 1.85;

        if (leftAngle < 20 && rightAngle < 20) {
            return;
        }

        if (puppet.isFacingRight(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(leftAngle));
        }

        if (puppet.isFacingLeft(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(rightAngle));
        }
    }
}

function cervicalLateralFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        36,//45
        90,
        false,
        params.ASSESSMENT_STATUS.CERVICAL_LATERAL_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.CERVICAL_LATERAL_FLEXION,
        params.ASSESSMENT_GIF.CERVICAL_LATERAL_FLEXION,
        params.ASSESSMENT_SCORE.CERVICAL_LATERAL_FLEXION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    const nose = keypoints[params.KEYPOINTS.NOSE];

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    const middlePointShoulder = {
        x: (lShoulder.x + rShoulder.x) / 2,
        y: (lShoulder.y + rShoulder.y) / 2
    };

    const rightAngle = Math.max(mathUtils.angle3Points(middlePointShoulder, nose, lShoulder) - 260, 0) * 1.2;
    const leftAngle = Math.max(mathUtils.angle3Points(middlePointShoulder, nose, rShoulder) - 250, 0) * 1.2;

    //CHECK FINISH
    const isInFinishRangeL = leftAngle >= -20 && leftAngle <= 20;
    const isInFinishRangeR = rightAngle >= -20 && rightAngle <= 20;
    if (hasExerciseFinished() && isInFinishRangeL && isInFinishRangeR)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.LUMBAR_FLEXION, params.ASSESSMENT_SCORE.CERVICAL_LATERAL_FLEXION);
        return;
    }

    //TESTING
    if (isFrontal) {
        updateScoreMax(Math.round(leftAngle), Math.round(rightAngle), valueNotDefined);
    }
}

function lumbarFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        72,//90
        180,
        true,
        params.ASSESSMENT_STATUS.LUMBAR_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.LUMBAR_FLEXION,
        params.ASSESSMENT_GIF.LUMBAR_FLEXION,
        params.ASSESSMENT_SCORE.LUMBAR_FLEXION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const nose = keypoints[params.KEYPOINTS.NOSE];

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    //CHECK FINISH
    
    if (hasExerciseFinished() && isFrontal)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.LUMBAR_EXTENSION, params.ASSESSMENT_SCORE.LUMBAR_FLEXION);
        return;
    }

    //TESTING
    if (!isFrontal) {
        const leftAngle = 1.4 * Math.abs(
            mathUtils.angle(lHip.x, lHip.y, lShoulder.x, lShoulder.y) - 90
        );
        
        const rightAngle = 1.4 *  Math.abs(
            180 - (mathUtils.angle(rShoulder.x, rShoulder.y, rHip.x, rHip.y) - 90)
        );

        if (puppet.isFacingRight(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(leftAngle));
        }

        if (puppet.isFacingLeft(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(rightAngle));
        }
    }
}

function lumbarExtensionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        32,//40
        180,
        true,
        params.ASSESSMENT_STATUS.LUMBAR_EXTENSION,
        params.ASSESSMENT_NOTIFICATIONS.LUMBAR_EXTENSION,
        params.ASSESSMENT_GIF.LUMBAR_EXTENSION,
        params.ASSESSMENT_SCORE.LUMBAR_EXTENSION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const nose = keypoints[params.KEYPOINTS.NOSE];

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);

    //CHECK FINISH
    
    if (hasExerciseFinished() && isFrontal)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.LUMBAR_LATERAL_FLEXION, params.ASSESSMENT_SCORE.LUMBAR_EXTENSION);
        return;
    }

    //TESTING
    if (!isFrontal) {
        const leftAngle = Math.max(
            mathUtils.angle(lHip.x, lHip.y, lShoulder.x, lShoulder.y) - 90, 0
        ) * 2.45;
        
        const rightAngle = Math.max(
            180 - (mathUtils.angle(rShoulder.x, rShoulder.y, rHip.x, rHip.y) - 90), 0
        ) * 2.3;

        if (puppet.isFacingRight(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(leftAngle));
        }

        if (puppet.isFacingLeft(nose, lShoulder, rShoulder)) {
           updateScoreMax(valueNotDefined, valueNotDefined, Math.round(rightAngle));
        }
    }
}

function lumbarLateralFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        36,//45
        90,
        false,
        params.ASSESSMENT_STATUS.LUMBAR_LATERAL_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.LUMBAR_LATERAL_FLEXION,
        params.ASSESSMENT_GIF.LUMBAR_LATERAL_FLEXION,
        params.ASSESSMENT_SCORE.LUMBAR_LATERAL_FLEXION
    );

    if (!enableToStart) {return;}
    
    //VALUES
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    const middlePointShoulder = {
        x: (lShoulder.x + rShoulder.x) / 2,
        y: (lShoulder.y + rShoulder.y) / 2
    };
    const middlePointHip = {
        x: (lHip.x + rHip.x) / 2,
        y: (lHip.y + rHip.y) / 2
    };

    const angle = (mathUtils.angle(middlePointHip.x, middlePointHip.y, middlePointShoulder.x, middlePointShoulder.y) - 90) * 2.35;

    //CHECK FINISH
    const isInFinishRange = angle >= -10 && angle <= 10;
    if (hasExerciseFinished() && isInFinishRange)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.HIP_FLEXION, params.ASSESSMENT_SCORE.LUMBAR_LATERAL_FLEXION);
        return;
    }

    //TESTING
    if (isFrontal) {
        if (angle > 10) {
           updateScoreMax(Math.round(angle), valueNotDefined, valueNotDefined);
        }

        if (angle < -10) {
           updateScoreMax(valueNotDefined, Math.abs(Math.round(angle)), valueNotDefined);
        }
    }
}

function hipFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        80,//100
        170,
        false,
        params.ASSESSMENT_STATUS.HIP_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.HIP_FLEXION,
        params.ASSESSMENT_GIF.HIP_FLEXION,
        params.ASSESSMENT_SCORE.HIP_FLEXION
    );
    
    if (!enableToStart) {return;}
    
    //VALUES
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));

    const leftAngle = Math.abs(
        mathUtils.angle(lKnee.x, lKnee.y, lHip.x, lHip.y) - 90
    ) * 1.2;

    const rightAngle = Math.abs(
        180 - (mathUtils.angle(rHip.x, rHip.y, rKnee.x, rKnee.y) - 90)
    ) * 1.2;

    //CHECK FINISH
    const areKneesCloseY = mathUtils.arePointsClose(lKnee.y, rKnee.y, uow);
    const areWristCloseToHip = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow);  
    const isInFinishRange = leftAngle <= 15 && rightAngle <= 15;       

    if (hasExerciseFinished() && isFrontal && areKneesCloseY && areWristCloseToHip && isInFinishRange)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.HIP_EXTENSION, params.ASSESSMENT_SCORE.HIP_FLEXION);
        return;
    }

    //TESTING
    let isDoingTest = !isFrontal;

    if (isDoingTest) {

        if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
            updateScoreMax(Math.round(leftAngle), valueNotDefined, valueNotDefined);
        }

        if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
            updateScoreMax(valueNotDefined, Math.round(rightAngle), valueNotDefined);
        }
    }
}

function hipExtensionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        24,//30
        90,
        false,
        params.ASSESSMENT_STATUS.HIP_EXTENSION,
        params.ASSESSMENT_NOTIFICATIONS.HIP_EXTENSION,
        params.ASSESSMENT_GIF.HIP_EXTENSION,
        params.ASSESSMENT_SCORE.HIP_EXTENSION
    );
    
    if (!enableToStart) {return;}
    
    //VALUES
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];

    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];

    const nose = keypoints[params.KEYPOINTS.NOSE];

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));

    const leftAngle = Math.abs(
        mathUtils.angle(lKnee.x, lKnee.y, lHip.x, lHip.y) - 90
    ) * 1.2;

    const rightAngle = Math.abs(
        180 - (mathUtils.angle(rHip.x, rHip.y, rKnee.x, rKnee.y) - 90)
    ) * 1.2;

    //CHECK FINISH
    const areKneesCloseY = mathUtils.arePointsClose(lKnee.y, rKnee.y, uow);
    const areWristCloseToHip = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow);
    const isInFinishRange = leftAngle <= 15 && rightAngle <= 15;

    if (hasExerciseFinished() && isFrontal && areKneesCloseY && areWristCloseToHip && isInFinishRange)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.SQUAT_KNEE_FLEXION, params.ASSESSMENT_SCORE.HIP_EXTENSION);
        return;
    }

    //TESTING
    let isDoingTest = !isFrontal;

    if (isDoingTest) {

        const ankleBehindL = nose.x < lKnee.x;
        const ankleBehindR = nose.x > rKnee.x;

        if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder) && ankleBehindL) {
            updateScoreMax(Math.round(leftAngle), valueNotDefined, valueNotDefined);
        }

        if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder) && ankleBehindR) {
            updateScoreMax(valueNotDefined, Math.round(rightAngle), valueNotDefined);
        }
    }
}

function squatKneeFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        120,//150
        170,
        false,
        params.ASSESSMENT_STATUS.SQUAT_KNEE_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.SQUAT_KNEE_FLEXION,
        params.ASSESSMENT_GIF.SQUAT_KNEE_FLEXION,
        params.ASSESSMENT_SCORE.SQUAT_KNEE_FLEXION
    );
    
    if (!enableToStart) {return;}
    
    //VALUES
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    //CHECK FINISH
    const areWristCloseToHip = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow);

    if (hasExerciseFinished() && isFrontal && areWristCloseToHip)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.CERVICAL_ROTATION, params.ASSESSMENT_SCORE.SQUAT_KNEE_FLEXION);
        return;
    }

    //TESTING
    let kneeBetweenHipAndWrist = false;
    let ankleBetweenHipAndKnee = false;

    if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
        kneeBetweenHipAndWrist = puppet.bodyPartIsBetweenX(lKnee, lHip, lWrist);
        ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(lAnkle, lHip, lKnee);
    }

    if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
        kneeBetweenHipAndWrist = puppet.bodyPartIsBetweenX(rKnee, rHip, rWrist);
        ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(rAnkle, rHip, rKnee);
    }

    let isDoingTest = !isFrontal && kneeBetweenHipAndWrist && ankleBetweenHipAndKnee;

    if (isDoingTest) {
        let leftAngle = Math.abs(180 - mathUtils.calculateAngle(lKnee, lHip, lAnkle, false));
        let rightAngle = Math.abs(180 - mathUtils.calculateAngle(rKnee, rHip, rAnkle, true));

        if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
            updateScoreMax(Math.round(leftAngle), valueNotDefined, valueNotDefined);
        }

        if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
            updateScoreMax(valueNotDefined, Math.round(rightAngle), valueNotDefined);
        }
    }

}

function cervicalRotationTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        72,//90
        120,
        false,
        params.ASSESSMENT_STATUS.CERVICAL_ROTATION,
        params.ASSESSMENT_NOTIFICATIONS.CERVICAL_ROTATION,
        params.ASSESSMENT_GIF.CERVICAL_ROTATION,
        params.ASSESSMENT_SCORE.CERVICAL_ROTATION
    );
    
    if (!enableToStart) {return;}
    
    //VALUES
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    const nose = keypoints[params.KEYPOINTS.NOSE];

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint)

    const middlePointShoulder = {
        x: (lShoulder.x + rShoulder.x) / 2,
        y: (lShoulder.y + rShoulder.y) / 2
    };

    const angle = (mathUtils.angle(middlePointShoulder.x, middlePointShoulder.y, nose.x, nose.y) - 90) * 3.5;

    //CHECK FINISH
    const isInFinishRange = angle >= -40 && angle <= 40;
    if (hasExerciseFinished() && isInFinishRange)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.HIP_INTERNAL_ROTATION, params.ASSESSMENT_SCORE.CERVICAL_ROTATION);
        return;
    }

    //TESTING
    const isDoingTest = isFrontal && Math.abs(angle) > 10;
    if (isDoingTest) {
        if (angle < 0) {
            updateScoreMax(valueNotDefined, Math.abs(Math.round(angle)), valueNotDefined);
        } else {
            updateScoreMax(Math.round(angle), valueNotDefined, valueNotDefined);
        }
    }
}

function hipInternalRotationTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        32,//40
        90,
        false,
        params.ASSESSMENT_STATUS.HIP_INTERNAL_ROTATION,
        params.ASSESSMENT_NOTIFICATIONS.HIP_INTERNAL_ROTATION,
        params.ASSESSMENT_GIF.HIP_INTERNAL_ROTATION,
        params.ASSESSMENT_SCORE.HIP_INTERNAL_ROTATION
    );
    
    if (!enableToStart) {return;}
    
    //VALUES
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));

    //CHECK FINISH
    const areAnkleClose = mathUtils.arePointsClose(lAnkle.y, rAnkle.y, uow);
    const areAnkleCloseToKneeX = mathUtils.arePointsClose(lAnkle.x, lKnee.x, uow) && mathUtils.arePointsClose(rAnkle.x, rKnee.x, uow);
    if (hasExerciseFinished() && areAnkleClose && areAnkleCloseToKneeX)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.HIP_EXTERNAL_ROTATION, params.ASSESSMENT_SCORE.HIP_INTERNAL_ROTATION);
        return;
    }

    //TESTING
    const areHipAndKneeCloseL = mathUtils.arePointsClose(lHip.y, lKnee.y, uow);
    const areHipAndKneeCloseR = mathUtils.arePointsClose(rHip.y, rKnee.y, uow);
    const isMovingAkleToOutside = rAnkle.x < lAnkle.x;

    const isDoingTest = (areHipAndKneeCloseL || areHipAndKneeCloseR) && isMovingAkleToOutside;

    if (isDoingTest) {
        const lAngle = Math.abs(180 - (mathUtils.angle(lKnee.x, lKnee.y, lAnkle.x, lAnkle.y) - 90)) * 1.25;
        const rAngle = Math.abs((mathUtils.angle(rAnkle.x, rAnkle.y, rKnee.x, rKnee.y) - 90)) * 1.25;

        if (areHipAndKneeCloseL) {
            updateScoreMax(Math.round(lAngle), valueNotDefined, valueNotDefined);
        }
        if (areHipAndKneeCloseR) {
            updateScoreMax(valueNotDefined, Math.round(rAngle), valueNotDefined);
        }
    }
}

function hipExternalRotationTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        48,//60
        90,
        false,
        params.ASSESSMENT_STATUS.HIP_EXTERNAL_ROTATION,
        params.ASSESSMENT_NOTIFICATIONS.HIP_EXTERNAL_ROTATION,
        params.ASSESSMENT_GIF.HIP_EXTERNAL_ROTATION,
        params.ASSESSMENT_SCORE.HIP_EXTERNAL_ROTATION
    );
    
    if (!enableToStart) {return;}
    
    //VALUES
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));

    //CHECK FINISH
    const areAnkleClose = mathUtils.arePointsClose(lAnkle.y, rAnkle.y, uow);
    const areAnkleCloseToKneeX = mathUtils.arePointsClose(lAnkle.x, lKnee.x, uow) && mathUtils.arePointsClose(rAnkle.x, rKnee.x, uow);
    if (hasExerciseFinished() && areAnkleClose && areAnkleCloseToKneeX)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.SQUAT_HIP_FLEXION, params.ASSESSMENT_SCORE.HIP_EXTERNAL_ROTATION);
        return;
    }

    //TESTING
    const areHipAndKneeCloseL = mathUtils.arePointsClose(lHip.y, lKnee.y, uow);
    const areHipAndKneeCloseR = mathUtils.arePointsClose(rHip.y, rKnee.y, uow);
    const isDoingTest = areHipAndKneeCloseL || areHipAndKneeCloseR;

    if (isDoingTest) {
        const lAngle = Math.abs(180 - (mathUtils.angle(lKnee.x, lKnee.y, lAnkle.x, lAnkle.y) - 90)) * 1.3;
        const rAngle = Math.abs((mathUtils.angle(rAnkle.x, rAnkle.y, rKnee.x, rKnee.y) - 90)) * 1.3;

        if (areHipAndKneeCloseL) {
            updateScoreMax(Math.round(lAngle), valueNotDefined, valueNotDefined);
        }
        if (areHipAndKneeCloseR) {
            updateScoreMax(valueNotDefined, Math.round(rAngle), valueNotDefined);
        }
    }
}

function squatHipFlexionTest(keypoints) {
    //INITIAL
    if (!userInStartPositionForTest) {
        detectInPositionToStartTest(keypoints);
        return;
    }

    let enableToStart = startOfTestAssessmentSetUp(
        80,//100
        170,
        false,
        params.ASSESSMENT_STATUS.SQUAT_HIP_FLEXION,
        params.ASSESSMENT_NOTIFICATIONS.SQUAT_HIP_FLEXION,
        params.ASSESSMENT_GIF.SQUAT_HIP_FLEXION,
        params.ASSESSMENT_SCORE.SQUAT_HIP_FLEXION
    );
    
    if (!enableToStart) {return;}
    
    //VALUES
    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    //CHECK FINISH
    const areWristCloseToHip = mathUtils.arePointsClose(rWrist.x, rHip.x, uow) && mathUtils.arePointsClose(lWrist.x, lHip.x, uow);

    if (hasExerciseFinished() && isFrontal && areWristCloseToHip)
    {
        endOfTestAssessment(params.ASSESSMENT_POSITION.COMPLETE, params.ASSESSMENT_SCORE.SQUAT_HIP_FLEXION);
        return;
    }

    //TESTING
    let kneeBetweenHipAndWrist = false;
    let ankleBetweenHipAndKnee = false;

    if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
        kneeBetweenHipAndWrist = puppet.bodyPartIsBetweenX(lKnee, lHip, lWrist);
        ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(lAnkle, lHip, lKnee);
    }

    if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder)) {
        kneeBetweenHipAndWrist = puppet.bodyPartIsBetweenX(rKnee, rHip, rWrist);
        ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(rAnkle, rHip, rKnee);
    }

    let isDoingTest = !isFrontal && kneeBetweenHipAndWrist && ankleBetweenHipAndKnee;

    if (isDoingTest) {
        let leftAngle = Math.abs(180 - mathUtils.calculateAngle(lHip, lKnee, lShoulder, false));
        let rightAngle = Math.abs(180 - mathUtils.calculateAngle(rHip, rKnee, rShoulder, true));

        if (puppet.isFacingRight(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder) && leftAngle > 15) {
            updateScoreMax(Math.round(leftAngle), valueNotDefined, valueNotDefined);
        }

        if (puppet.isFacingLeft(keypoints[params.KEYPOINTS.NOSE], lShoulder, rShoulder) && rightAngle > 15) {
            updateScoreMax(valueNotDefined, Math.round(rightAngle), valueNotDefined);
        }
    }

}

function hasExerciseFinished() {
    if (bestLeftScore === undefined || bestRightScore === undefined || bestTotalScore === undefined) {
        return false;
    }

    if (bestTotalScore == valueNotDefined) {
        return bestLeftScore >= minimumValidAngleForAssessment && bestRightScore >= minimumValidAngleForAssessment;
    } else {
        return bestTotalScore >= minimumValidAngleForAssessment;
    }
}

module.exports = poseDetection;